import React from 'react';
import { FaChalkboardTeacher, FaChartBar, FaFileExcel, FaUpload } from 'react-icons/fa';
import CopyToClipboard from '../../../core/components/CopyToClipboard';
import { apiUrl, getToken, urlWebsite } from '../../../../../config';
import userEvent from '@testing-library/user-event';
import { useAuth } from '../../../../../hooks/Auth';
import downloadit from '../../../../../utils/download';
import { useLoading } from '../../../../../hooks/LoadingHook';
import FormComponent from '../../../core/components/FormComponent';
import { FormHandles } from '@unform/core';
import api from '../../../../../services/api';
import { useToast } from '../../../../../hooks/Toast';
import { useModal } from '../../../../../hooks/Modal';

interface ICreateModule {
  lineData: Record<string, any>;
}


export default function UploadParticipants({
  lineData,
}: ICreateModule): JSX.Element {

  const { user } = useAuth();
  const { addLoading, removeLoading } = useLoading();
  const { addToast } = useToast();
  const { addModal, removeModal } = useModal();


  const send = async (items) => {

    try {
      const response = await api.post(`/upload-attendances/${lineData?._id?.toString()}`, { items });
      addToast({ title: 'Enviado com sucesso', type: 'success' })
    }
    catch (err) {
      addToast({ title: 'Erro ao enviar', type: 'error' })
    }
  }


  const setCallback = async (
    data: Record<string, any>,
    formRef: React.RefObject<FormHandles>,
  ): Promise<void> => {
    try {
      const formData = new FormData();
      formData.append('file', data.image)
      const response = await api.post(`/converter/xlsx-to-json`, formData);

      if (response.status !== 200) {
        return addToast({ type: 'error', title: 'Erro ao salvar' });
      }





      const items: Record<string, any>[] = [];

      let x = 0;

      while (x < response?.data?.rows?.length) {

        const where: Record<string, any>[] = [];
        if (response?.data?.rows?.[x]?.document_number) {
          where.push({ document_number: response?.data?.rows?.[x]?.document_number });
        }
        if (response?.data?.rows?.[x]?.email) {
          where.push({ email: response?.data?.rows?.[x]?.email });
        }

        const students = where?.length > 0 ? await api.get(`/students`, {
          params: {
            where: {
              "$or": where
            }
          }
        }) : { data: { rows: [] } };

        const student = students?.data?.rows?.[0];
        items?.push({ name: student?.name, email: student?.email, client_id: student?._id?.toString(), user_id: student?._id?.toString(), project_id: lineData?.project_id, event_id: lineData?.project_id })

        addToast({ title: `Verificando ${x + 1}/${response?.data?.rows?.length}`, type: 'info' })

        x += 1;
      }





      addModal({
        title: '', theme: 'whiteModal', content: <><button className='defaultButtonMini' onClick={() => {

          send(items)

        }}>Enviar</button><table className='table'>

            <tbody>
              <tr><td>Nome</td><td>E-mail</td><td>ID do Cliente</td><td>Projeto</td><td>Evento</td></tr>
              {items?.map(i => <tr>
                <td>{i?.name}</td>
                <td>{i?.email}</td>
                <td>{i?.client_id}</td>
                <td>{i?.project_id}</td>
                <td>{i?.event_id}</td>
              </tr>)}
            </tbody>


          </table></>
      })

    } catch (err) {


      return addToast({ type: 'error', title: err?.response?.data?.error || err?.response?.data?.message });
    }
  };

  const handleCreate = (): void => {
    const content = (
      <FormComponent schema={{
        image: {
          model: 'uploadImage',
          type: 'upload',
          name: 'image',
          label: 'Imagem',
        }
      }} setCallback={setCallback} />
    );
    if (content) {
      const keyModal = addModal({ title: 'Adicionar certificados', content, key: 'modal', theme: "whiteModal" });
    }
  };



  const moduleList = (): JSX.Element => {

    return <> {user && user.profile === 1 ? <button className='lineIcon'><FaUpload onClick={() => handleCreate()} color="#333" size={30} title="Importar Lista de participantes" /></button> : <></>}
    </>;
  }



  return moduleList();
}
