import React, { useCallback, useRef, useEffect, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Container, CertificateLine, Error } from './styles';
import Input from '../../components/Forms/Input';
import { useToast } from '../../hooks/Toast';
import { Button } from '../../components/Button/styles';
import api from '../../services/api';
import Loader from '../../components/Loader';
import { hash, urlWebsite } from '../../config';
import CheckboxInput from '../../components/Forms/CheckboxInput';
import * as Yup from 'yup';
import { useLanguage } from '../../hooks/Language';
import { useAuth } from '../../hooks/Auth';
import getValidationErrors from '../../utils/getValidationErrors';

import Research from './FormContainer';
import Exam from './FormContainer2';
import { MdError } from 'react-icons/md';
import { useModal } from '../../hooks/Modal';
import { useParams } from 'react-router-dom';
import { useConfiguration } from '../../hooks/Configuration';
import { handleCertificateDownload } from '../DashboardPages/pages/CertificatesUsers/modules/ExportPDF';
import QRCode from 'qrcode.react';
interface ICertificates {
  certificate_id_response: { title: string };
  hash: string;
  name?: string;
}

interface IOptions {
  id: string;
  value: string;
  label: string;


}




interface IAnswers {
  id: string;
  value: string;
  label: string;
}

interface IQuestion {
  title: string;
  options: Array<{ question: string, value: string }>;
  correct: string;
}

interface IQuestions {
  title: string;
  questions: Array<IQuestion>
}

interface IResponse {
  number: number;
  answers: Array<string>;
}

interface ICertificateUser {
  id: string;
  answers: Array<Record<string, any>>;
  hash: string;
  certificate_id_response: {
    proof: IQuestions
  },
  user_id: number;
}





const Certificados: React.FC = () => {
  const [certificates, setCertificates] = useState<Array<ICertificates>>([]);
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(false);
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const { translate, language } = useLanguage();
  const { user } = useAuth();
  const [success, setSuccess] = useState(true);
  const [getNumber, setGetNumber] = useState(0);
  const [emailInfo, setEmailInfo] = useState('');
  const [errorMessage, setErrorMessage] = useState<Array<string>>([]);
  const { addModal } = useModal();
  const { projectHash } = useParams<Record<string, string>>();
  const { configuration } = useConfiguration();
  const [certificate, setCertificate] = useState<ICertificateUser>({} as ICertificateUser);

  const verify = async data => {
    setLoading(true);
    if (!data.email) {
      setLoading(false);
      return addToast({ title: translate('Preencha o campo email'), type: 'error' });
    }
    try {
      const { email } = data;

      await api.post(`/verify-certificates`, { email: email?.toLowerCase()?.trim(), project_id: projectHash });
      const response = await api.post(`/verify-certificates-v2`, { email: email?.toLowerCase()?.trim(), project_id: projectHash });
      if (!response) {
        setLoading(false);
        return addToast({
          title: translate('Nenhum certificado encontrado'),
          type: 'error',
          description: translate('Verifique se inseriu o nome ou email correto. Caso esteja procurando pelo nome, utilize acentos onde necessário (Ex: João)')
        });
      }

      response.data.content = response?.data?.content?.filter(a => a?.project_id === projectHash)

      setEmailInfo(email);

      setCertificates(response.data.content);

      // setSuccess(true);

      setActive(true);
      setLoading(false);
    } catch (err) {

      setLoading(false);
      return addToast({
        title: translate('Nenhum certificado encontrado'),
        type: 'error',
        description: translate('Verifique se inseriu o email correto ou não há espaços em seu email')
      });
    }
  };

  const [questions, setQuestions] = useState<IQuestions>({} as IQuestions);

  const resetVerify = () => {
    setCertificates([]);
    setActive(false);
  };

  const checkAnswers = (data) => {

    const response: IResponse = {
      number: 0,
      answers: []
    }

    let x = 1;
    console.log(certificate?.certificate_id_response)
    certificate?.certificate_id_response?.proof?.questions.map((question) => {

      if (data[`p${x}`] && data[`p${x}`][0] === question.correct) { response.number = response.number + 1; }
      response.answers.push(data[`p${x}`][0]);

      x = x + 1;

    })

    return response;


  }

  const checkPerguntas = async (data) => {


    setLoading(true);



    try {
      formRef.current?.setErrors({});






      /* const response = await api.post(`/signup`, {
        ...data
      });*/


      setLoading(false);

      let answers = checkAnswers(data);





      await api.post(`/confirmar-certificado`, { id: certificate?.id, answers, email: emailInfo });
      setGetNumber(answers.number);
      setSuccess(true);

      handleCertificateDownload({ certificate });
      // window.location.href = `https://api-certificados.encontrodigital.com.br/${hash}/certificados/${certificate?.hash}`;
      //  window.location.href = `http://api-certificados.encontrodigital.com.br/${hash}/certificadosv2/${certificate?.hash}`;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        if (formRef && formRef.current && formRef.current.setErrors) {
          formRef.current.setErrors(errors);
        }
        setLoading(false);

        const errorsString = Object.keys(errors).reduce(
          (prev: Array<string>, key: string): Array<string> => {
            prev = [...prev, errors[key]];
            return prev;
          },
          [],
        );

        setErrorMessage(errorsString);
        setLoading(false);
        return;
      }

      setErrorMessage([
        err?.response?.data?.message ||
        err?.response?.data?.error ||
        'Erro ao acessar seu certificado',
      ]);
      setLoading(false);
    }
    setLoading(false);
  };




  const handleCertificate = async (e, cert) => {
    e.preventDefault();
    setLoading(true);


    /* verificar se a pesquisa foi criada */


    if (cert?.certificate_id_response?.satisfaction_survey_id) {

      const researchCheck = await api.get(`/check-research/${cert.email}/${cert?.certificate_id_response?.satisfaction_survey_id}`);

      if (researchCheck?.data?.message !== 2) {
        addModal({
          theme: 'whiteModal max700',
          title: '',
          content: <Research cert={cert} handle={() => handleCertificate(e, cert)} survey_id={cert?.certificate_id_response?.satisfaction_survey_id} content={researchCheck?.data?.content} id={cert.email} />,
          key: 'research',

        });
        setLoading(false);
        return;
      }

      if (cert?.certificate_id_response?.exam_id) {
        const researchCheck = await api.get(`/check-exam/${cert.user_id}/${cert?.certificate_id_response?.exam_id}`);

        if (researchCheck?.data?.message !== 2) {
          addModal({
            theme: 'whiteModal max700',
            title: '',
            content: <Exam handle={() => handleCertificate(e, cert)} exam_id={cert?.certificate_id_response?.exam_id} content={researchCheck?.data?.content} id={cert.user_id} />,
            key: 'exam',

          });
          setLoading(false);
          return;
        }

        else {
          handleCertificateDownload({ certificate: cert });
          // window.location.href = `https://api-certificados.encontrodigital.com.br/${hash}/certificadosv2/${cert.hash}`;
          setLoading(false);
        }


      }
      else {
        handleCertificateDownload({ certificate: cert });
        // window.location.href = `https://api-certificados.encontrodigital.com.br/${hash}/certificadosv2/${cert.hash}`;
        setLoading(false);
      }


    }

    else if (cert?.certificate_id_response?.exam_id) {
      const researchCheck = await api.get(`/check-exam/${cert.user_id}/${cert?.certificate_id_response?.exam_id}`);

      if (researchCheck?.data?.message !== 2) {
        addModal({
          theme: 'whiteModal',
          title: '',
          content: <Exam handle={() => handleCertificate(e, cert)} exam_id={cert?.certificate_id_response?.exam_id} content={researchCheck?.data?.content} id={cert.user_id} />,
          key: 'exam',

        });
        setLoading(false);
        return;
      }

      else {
        handleCertificateDownload({ certificate: cert });
        // window.location.href = `https://api-certificados.encontrodigital.com.br/${hash}/certificadosv2/${cert.hash}`;
        setLoading(false);
      }


    }

    else {
      handleCertificateDownload({ certificate: cert });
      // window.location.href = `https://api-certificados.encontrodigital.com.br/${hash}/certificadosv2/${cert.hash}`;
      setLoading(false);

    }



  }

  return (
    <Container style={{ padding: '30px 10px', background: '#fff', borderRadius: '15px' }}>
      {configuration?.theme_data?.certificate_text ? <p style={{ color: '#333', margin: '30px auto', maxWidth: '500px', textAlign: 'center' }}>{configuration?.theme_data?.certificate_text}</p> : <></>}
      {configuration?.current_event_id_response?.certificate_description ? <p className='darkColor' style={{ color: '#333', margin: '30px auto', maxWidth: '500px', textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: configuration?.current_event_id_response?.[`certificate_description_${language}`] ? configuration?.current_event_id_response?.[`certificate_description_${language}`] : configuration?.current_event_id_response?.certificate_description }} /> : <></>}
      {!active && (
        <Form style={{ textAlign: 'center' }} onSubmit={verify} ref={formRef}>

          <h2 style={{ marginBottom: '20px', color: 'rgb(0, 14, 64)' }}>{translate("Visualizar meus certificados")}</h2>


          <Input placeholder="Insira seu email" name="email" type="text" />
          <button className="buttonBigGreen" style={{ margin: '5px auto', width: '100%' }} type="submit">
            {translate("Verificar")}
          </button>
        </Form>
      )}
      {active && success && (
        <>
          <h2 style={{ marginBottom: '20px', color: 'rgb(0, 14, 64)' }}>{translate("Meus Certificados")}</h2>
          <div>
            {certificates && certificates.length > 0 ? (
              certificates.map(cert => (
                <CertificateLine>
                  <a onClick={(e) => handleCertificate(e, cert)}
                    href={'#'}
                    target="_BLANK"
                  >
                    <button type="button" className="defaultButton">{translate("Download")}</button>
                    <aside style={{ display: 'none' }}>
                      <QRCode id={`canva-${cert.hash}`}
                        size={100}
                        value={`${urlWebsite}/validar-certificado/${cert.hash}`} renderAs="canvas" />
                    </aside>
                  </a>
                  <p>{cert?.certificate_id_response?.title || ''}<br />
                    Parcipante: {cert?.name}</p>
                </CertificateLine>
              ))
            ) : (
              <p style={{ color: '#333' }}>{translate("Nenhum certificado encontrado")}</p>
            )}
          </div>

          <button className="defaultButton"
            onClick={() => resetVerify()}
            style={{ margin: '5px auto', width: '100%', maxWidth: '200px' }}
            type="button"
          >
            {translate("Voltar")}
          </button>
        </>
      )}

      {loading && <Loader message={translate("Verificando")} />}


    </Container>
  );
};
export default Certificados;
