import React from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules'
import { Container } from './styles';
import { useSocket } from '../../../../hooks/Socket';
import { Button } from '../../core/components/Forms/Button/styles';
import speakerLink from './modules/speakerLink';
import exportList from './modules/ExportList';
import moderatorLink from './modules/ModeratorLink';
import socketLink from './modules/UpdateLesson';
import { UpdateCoursesModule } from '../Courses';
import { FiSpeaker, FiStar } from 'react-icons/fi';
import { useParams } from 'react-router-dom';
import listParticipants from './modules/ListParticipants';
import uploadParticipants from './modules/UploadAttendances';
interface ICreateModule {
  lineData: Record<string, any>;



}

interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface ILine {
  [key: string]: string;
}
interface IParams {
  projectHash: string;
  eventHash: string;
}

const Lesson: React.FC = () => {
  const endpoint = '/lessons';
  const { socket } = useSocket();
  const title = 'Palestras';


  const formSchema = {
    time_video: {
      model: 'input',
      name: 'time_video',
      label: 'Tempo de vídeo (em minutos) ex: 90',

      defaultValue: '',
    },

    reference: {
      model: 'selectSimple',
      name: 'reference',
      label: 'Sala de Referência',



      options: [


        { label: 'SALA 01', value: 'SALA 01' },
        { label: 'SALA 02', value: 'SALA 02' },
        { label: 'SALA 03', value: 'SALA 03' },
        { label: 'SALA 04', value: 'SALA 04' },
        { label: 'SALA 05', value: 'SALA 05' },
        { label: 'SALA 06', value: 'SALA 06' },
        { label: 'SALA 07', value: 'SALA 07' },
        { label: 'SALA 08', value: 'SALA 08' },
        { label: 'SALA 09', value: 'SALA 09' },
        { label: 'SALA 10', value: 'SALA 10' },
        { label: 'SALA 11', value: 'SALA 11' },
        { label: 'SALA 12', value: 'SALA 12' },
        { label: 'SALA 13', value: 'SALA 13' },
        { label: 'SALA 14', value: 'SALA 14' },
        { label: 'SALA 15', value: 'SALA 15' },
        { label: 'SALA 15', value: 'SALA 15' },
        { label: 'SALA 16', value: 'SALA 16' },
        { label: 'SALA 17', value: 'SALA 17' },
        { label: 'SALA 18', value: 'SALA 18' },
        { label: 'SALA 19', value: 'SALA 19' },
        { label: 'SALA 20', value: 'SALA 20' },



      ]

    },
    release_date: {
      model: 'datePickerHour',
      name: 'release_date',
      label: 'Data de Lançamento',
      defaultValue: '',
    },

    title: {
      model: 'input',
      name: 'title',
      label: 'Título',
      defaultValue: '',
    },
    title_es: {
      model: 'input',
      name: 'title_es',
      label: '(Espanhol) Título',
      defaultValue: '',
    },
    title_en: {
      model: 'input',
      name: 'title_en',
      label: '(Inglês) Título',
      defaultValue: '',
    },
    keywords: {
      model: 'ArrayInputIncluder',
      name: 'keywords',
      label: 'Título',
      defaultValue: '',
    },
    start_hour: {
      model: 'input',
      name: 'start_hour',
      label: 'Horário',

      defaultValue: '',
    },
    time: {
      model: 'input',
      name: 'time',
      label: 'Iniciar o vídeo no tempo: Ex: 1m30s',

      defaultValue: '',
    },
    room_name: {
      model: 'input',
      name: 'room_name',
      label: 'Nome da Sala',
      defaultValue: '',
    },
    room_color: {
      model: 'input',
      name: 'room_color',
      label: 'Cor da Sala',
      defaultValue: '',
    },
    category_name: {
      model: 'input',
      name: 'category_name',
      label: 'Subtítulo',
      defaultValue: '',
    },
    class_name: {
      model: 'selectSimple',
      name: 'class_name',
      label: 'Classe',

      options: [
        { label: 'Com texto', value: 'withText' },
        { label: 'Sem texto', value: 'notext' },


      ]

    },
    allow_categories: {
      model: 'ArrayInputIncluder',
      name: 'allow_categories',
      label: 'Categorias Permitidas',
      defaultValue: '',
    },


    status: {
      model: 'selectSimple',
      name: 'status',
      label: 'Status',
      defaultValue: '1',
      options: [
        { label: 'Em breve', value: 1 },
        { label: 'Ao vivo', value: 2 },
        { label: 'Finalizada', value: 3 },

      ]

    },
    category_id: {
      alias: 'category_id',
      model: 'selectApi',
      name: 'category_id',
      label: 'Categoria',
      defaultValue: 1,
      endpoint,
    },
    dificulty_id: {
      alias: 'dificulty_id',
      model: 'selectApi',
      name: 'dificulty_id',
      label: 'Nível',
      defaultValue: 1,
      endpoint,
    },
    video_type_id: {
      model: 'selectSimple',
      name: 'video_type_id',
      label: 'Status',
      defaultValue: 'Vimeo',
      options: [
        { label: 'Vimeo', value: 'vimeo' },
        { label: 'Youtube', value: 'youtube' },
        { label: 'Zoom', value: 'zoom' },
        { label: 'On Demand', value: 'ondemand' },
        { label: 'Iframe', value: 'iframe' },
        { label: 'Player Direto', value: 'direct' },
      ]

    },
    poster_id: {
      alias: 'poster_id',
      model: 'selectApi',
      name: 'poster_id',
      label: 'Lista de Slides',
      endpoint,
    },
    video_id: {
      model: 'input',
      name: 'video_id',
      label: 'Código do Vídeo',
      defaultValue: '',
    },
    zoom_id: {
      model: 'input',
      name: 'zoom_id',
      label: 'ID do Zoom',
      defaultValue: '',
    },

    chat_id: {
      model: 'input',
      name: 'chat_id',
      label: 'Chat de Referência',
      defaultValue: 0,
    },
    visible: {
      model: 'input',
      name: 'visible',
      label: 'Visível no Grid? 1 Não, 2 Sim',
      defaultValue: 2,
    },
    languages: {
      model: 'jsonList',
      type: 'text',
      name: 'languages',
      label: 'Línguas',
      list: [
        { label: 'Português', name: 'ptBr' },
        { label: 'Inglês', name: 'en' },
        { label: 'Espanhol', name: 'es' },
      ],
    },

    /* slides: {
       model: 'imageGalleryInput',
       type: 'input',
       name: 'slides',
       label: 'Slides de sala',
       list: [
         { label: 'Imagem', name: 'url' },
         { label: 'Título', name: 'title' },
   
         { label: 'Link', name: 'link_url' },
   
       ],
     },
   
     */

  };

  const formSchemaUpdate = {
    event_id: {
      model: 'input',
      name: 'event_id',
      label: 'ID do Evento',
      defaultValue: '',
    },
    ...formSchema,
    icon: {
      model: 'uploadImage',
      type: 'upload',
      name: 'icon',
      label: 'Ícone',
    },
    image: {
      model: 'uploadImage',
      type: 'upload',
      name: 'image',
      label: 'Foto',
    },
    exclusive_image: {
      model: 'uploadImage',
      type: 'upload',
      name: 'exclusive_image',
      label: 'Slide para eventos exclusivos',
    },
    background_image: {
      model: 'uploadImage',
      type: 'upload',
      name: 'background_image',
      label: 'Fundo da Sala',
    }
  };

  const formSchemaUpdateSpeakers = {
    speakers: {
      model: 'JsonInput',
      bodyRender: (data: Record<string, any>) => (
        <nav>
          <strong>
            (
            {data.id})
            {' '}
            {data.name}
          </strong>
        </nav>
      ),
      searchRender: (data: Record<string, any>) => (
        <>
          <strong>
            (
            {data.id})
            {data.name}
          </strong>
        </>
      ),
      storageType: 'id',
      endpoint: `/speakers`,
      name: 'speakers',
      label: 'Palestrantes',
    },
  };






  const formValidation: Record<string, ValidationSchema> = {
    title: {
      name: 'title',
      type: 'string',
      label: 'Título',
      rules: [{ type: 'required' }],
    },
    video_id: {
      name: 'video_id',
      type: 'string',
      label: 'Código do Vìdeo',
      rules: [{ type: 'required' }],
    },
  };


  const { projectHash, eventHash } = useParams<IParams>();

  const schema: Record<string, any> = {
    _id: { ref: '_id', column: '_id', label: 'ID' },
    hash: { ref: 'hash', column: 'hash', label: 'Hash', show: false },
    project_id: { ref: 'project_id', column: 'project_id', label: 'Projeto', show: false },
    event_id: { ref: 'event_id', column: 'event_id', label: 'Evento', show: false },
    reference: { ref: 'reference', column: 'reference', label: 'SALA Referência' },
    title: { ref: 'title', column: 'title', label: 'Título' },
    room_name: { ref: 'room_name', column: 'room_name', label: 'SALA' },
    release_date: { ref: 'release_date', column: 'release_date', mask: 'date', label: 'Data' },
    start_hour: { ref: 'start_hour', column: 'start_hour', label: 'Horário' },

    video_type_id: { ref: 'video_type_id', column: 'video_type_id', label: 'Título' },
    video_id: { ref: 'video_id', column: 'video_id', label: 'ID do Vídeo' },
    class_name: { ref: 'class_name', column: 'class_name', label: 'Classe' },
    createdAt: {
      ref: 'created_at',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };

  function updateLessonModule({

    lineData,


  }: ICreateModule): JSX.Element {

    const clearLessonCache = () => {
      socket?.emit('clearChatCache', { room_id: lineData.id });
    }

    return <Button style={{ padding: '5px' }} onClick={() => clearLessonCache()}>Limpar Cache</Button>

  }



  const config: ISearchComponent = {
    idKey: `${endpoint}-${projectHash}-${eventHash}`,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [
      () =>
        lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
    bodyModules: [
      (lineData: Record<string, any>) =>
        exportList({
          lineData,

        }),

      (lineData: Record<string, any>) =>
        moderatorLink({
          lineData,

        }),
      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema: formSchemaUpdateSpeakers,
          validationSchema: {},
          endpoint,
          Icon: FiSpeaker

        }),
      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema: formSchemaUpdate,
          validationSchema: {},
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        lineModules.delete({
          lineData,
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
  };

  const config2: ISearchComponent = {
    idKey: `${endpoint}-${projectHash}-${eventHash}`,
    title: 'aulas',
    endpoint: '/lessons',
    schema: schema,
    autoStart: true,
    headModules: [
      () =>
        lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
    bodyModules: [
      (lineData: Record<string, any>) =>
        exportList({
          lineData,

        }),
      (lineData: Record<string, any>) =>
        moderatorLink({
          lineData,

        }),


      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema: formSchemaUpdate,
          validationSchema: {},
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title: "Palestrantes",
          formSchema: formSchemaUpdateSpeakers,
          validationSchema: {},
          endpoint,
          Icon: FiStar
        }),
      (lineData: Record<string, any>) =>
        lineModules.delete({
          lineData,
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
      (lineData: Record<string, any>) => listParticipants({ lineData }),
      (lineData: Record<string, any>) => uploadParticipants({ lineData }),

    ],
  };

  return (
    <Container>
      <UpdateCoursesModule />
      <SearchComponent {...config2} />
    </Container>
  );
};

export default Lesson;
